import React, { FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAuth } from '../../containers/AuthWrapper'
import { State } from '../../reducers'

const shouldAllowRoute =
  localStorage.getItem('test_identifier') === process.env.REACT_APP_TEST_IDENTIFIER // unblocks protected routes for automation tests

const ProtectedRoute: FC<{ redirectTo?: string; disabled?: boolean } & RouteProps> = ({
  redirectTo,
  disabled,
  ...routeProps
}) => {
  const waitForClose = useRef<boolean>(false)
  const { isAuth, openProviderMenu } = useAuth()
  const { isProviderMenuOpen } = useSelector((state: State) => state.ui)

  // open provider if is not auth
  useEffect(() => {
    if (waitForClose.current || isProviderMenuOpen || isAuth || shouldAllowRoute) {
      return
    }

    openProviderMenu()

    waitForClose.current = true
    // TODO:
    // https://app.shortcut.com/dexguru/story/21253
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, isProviderMenuOpen])

  if (disabled) {
    return null
  }

  if (isAuth || shouldAllowRoute) {
    return <Route {...routeProps} />
  }

  if (waitForClose.current && !isProviderMenuOpen && !isAuth) {
    return <Redirect to={redirectTo || '/'} />
  }

  return null
}

export default ProtectedRoute
