import { TxsLoaderReducerState } from '../../../services/TxsLoader'
import { poolActivityActiveFilters } from '../PoolActivity.enums'

export const initPoolActivityState: TxsLoaderReducerState = {
  isLoading: false,
  filters: poolActivityActiveFilters.all,
  currentData: [],
  previousData: [],

  isJustRefreshed: false,
}
