const getFullHistoryButtonThreshold = ({
  dataLength,
  rowsToShow,
}: {
  dataLength?: number
  rowsToShow?: number
}): number | undefined => {
  // can be undefined 0
  if (!dataLength || !rowsToShow) {
    return
  }

  const maxDataLengthToShow = rowsToShow * 2

  if (dataLength < maxDataLengthToShow) {
    return dataLength
  }

  return maxDataLengthToShow
}

export default getFullHistoryButtonThreshold
