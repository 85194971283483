import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { setTraderProfileTab } from '../../actions/traderProfileActions'
import { TokenWithApprovalContext } from '../../model'
import { TraderProfileTabs } from '../../reducers/traderProfileReducer'
import amplitudeService from '../../services/amplitudeService'
import {
  setFilters,
  setRowsToShow,
  TxsLoaderAction,
  TxsLoaderReducerState,
} from '../../services/TxsLoader'
import getFullHistoryButtonThreshold from './helpers/getFullHistoryButtonThreshold'
import { poolActivityActiveFilters, PoolActivityFilterNames } from './PoolActivity.enums'

type UsePoolActivityManagerProps = {
  currentToken?: TokenWithApprovalContext
  state: TxsLoaderReducerState
  dispatch: React.Dispatch<TxsLoaderAction>
}

type UsePoolActivityManagerReturn = {
  fullHistoryButtonThreshold?: number

  onClickPoolActivityRow: () => void
  onAdd: () => Promise<void>
  onAll: () => Promise<void>
  onRemove: () => Promise<void>
  onChangeRowsToShow: (amount: number) => void
}

const usePoolActivityManager = ({
  currentToken,
  state,
  dispatch,
}: UsePoolActivityManagerProps): UsePoolActivityManagerReturn => {
  const reduxDispatch = useDispatch()
  const didMountRef = useRef(false)

  const handleAll = async (): Promise<void> => {
    amplitudeService.poolActivitySidebarChangeFilter({
      currentToken,
      filterName: PoolActivityFilterNames.all,
    })
    dispatch(setFilters(poolActivityActiveFilters[PoolActivityFilterNames.all]))
  }

  const handleAdd = async (): Promise<void> => {
    amplitudeService.poolActivitySidebarChangeFilter({
      currentToken,
      filterName: PoolActivityFilterNames.adds,
    })
    dispatch(setFilters(poolActivityActiveFilters[PoolActivityFilterNames.adds]))
  }

  const handleRemove = async (): Promise<void> => {
    amplitudeService.poolActivitySidebarChangeFilter({
      currentToken,
      filterName: PoolActivityFilterNames.removes,
    })
    dispatch(setFilters(poolActivityActiveFilters[PoolActivityFilterNames.removes]))
  }

  const handleChangeRowsToShow = React.useCallback(
    (amount: number): void => dispatch(setRowsToShow(amount)),
    [dispatch]
  )

  // sometimes some data is removed from the queried txns when current token is 0
  const fullHistoryButtonThreshold = getFullHistoryButtonThreshold({
    dataLength: (state.currentData || []).length,
    rowsToShow: state.rowsToShow,
  })

  const handleClickPoolActivityRow = (): void => {
    reduxDispatch(setTraderProfileTab({ tab: TraderProfileTabs.lp }))
  }

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }
    dispatch(setFilters(poolActivityActiveFilters[PoolActivityFilterNames.all]))
    // need to reset filters when token had changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken?.id])

  return {
    fullHistoryButtonThreshold,
    onClickPoolActivityRow: handleClickPoolActivityRow,
    // filters
    onAll: handleAll,
    onAdd: handleAdd,
    onRemove: handleRemove,
    // init
    onChangeRowsToShow: handleChangeRowsToShow,
  }
}
export default usePoolActivityManager
