import { FC } from 'react'
import { Link } from 'react-router-dom'

const FullHistoryLink: FC<{ to: string }> = ({ to }) => (
  <div className="full-history-button-container">
    <Link to={to} className="full-history-button">
      Full history
    </Link>
  </div>
)

export default FullHistoryLink
