import { FilterOptions, TransactionTypes } from '../../model'

export enum PoolActivityFilterNames {
  all = 'all',
  adds = 'adds',
  removes = 'removes',
}

export const poolActivityActiveFilters: Record<PoolActivityFilterNames, Partial<FilterOptions>> = {
  [PoolActivityFilterNames.all]: {
    transaction_types: [TransactionTypes.mint, TransactionTypes.burn],
  },
  [PoolActivityFilterNames.adds]: {
    transaction_types: [TransactionTypes.mint],
  },
  [PoolActivityFilterNames.removes]: {
    transaction_types: [TransactionTypes.burn],
  },
}

export enum PoolActivityMessages {
  loadingMessage = 'Loading pools activity data...',
  emptyMessage = 'It looks like there are currently no liquidity TXs available.',
}
