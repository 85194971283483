import { UIState } from '../../../reducers/uiReducer'
import { SidebarType } from '../../Sidebar/sidebarUtils'

// good for selectors
const getIsPopupOpened = (uiState: UIState): boolean => {
  const { isSidebarOpen: openedSidebar, isTokenProfileOpen, isTraderProfileOpened } = uiState
  const isPopupOpened =
    openedSidebar !== SidebarType.none || isTokenProfileOpen || isTraderProfileOpened
  return isPopupOpened
}

export default getIsPopupOpened
