import { model } from '@telekomconsalting/dex-guru-model'
import classNames from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { isValidLiquidityProviderCategory } from '../../helpers/validators'
import { Address, FullSidebarsModifier, TradeBarData, TransactionModel } from '../../model'
import { State } from '../../reducers'
import { buildTraderProfileUrl } from '../../routes'
import {
  getCurrencyName,
  getNetworkConfig,
  getTokenAddress,
  isTitleNaN,
  numberWithCommas,
  pickValueBasedOnCurrency,
} from '../../utils'
import { CategoryIcon } from '../CategoryIcon/CategoryIcon'
import { isOwnTx } from '../Sidebar/sidebarUtils'
import TimeAgo from '../TimeAgo/TimeAgo'
import TokenValueBar from '../TokenValueBar'
import { getDeltaClassName, getWalletCategory } from '../Transactions/helpers'
import TransactionOwn from '../Transactions/TransactionOwn'
import TransactionsAmountTokens from '../Transactions/TransactionsAmountTokens'

interface PoolActivityRowProps extends TransactionModel {
  modifier: FullSidebarsModifier
  classAnimated: string
  tradeBarData?: TradeBarData
  onClickRow?: () => void
  account?: Address | null
}
const PoolActivityRow: FC<PoolActivityRowProps> = (props) => {
  const { isMobile } = useSelector((state: State) => state)
  const selectedCurrency = useSelector((state: State) => state.currency)
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const tokenNetwork = currentToken?.network

  if (!currentToken || !tokenNetwork) {
    return null
  }

  const deltaClassName = getDeltaClassName(
    props.transactionType,
    currentToken?.id,
    props.fromAddress,
    props.modifier
  )

  const networkConfig = getNetworkConfig(tokenNetwork)

  const currencyDisplayName = getCurrencyName(tokenNetwork, selectedCurrency)

  const ownTrade = isOwnTx(props.wallets, props.account)

  const walletCategory = getWalletCategory(props.walletsCategories)

  const currentTokenAddress = getTokenAddress(currentToken).toLowerCase()
  const currentTokenIndex = props.tokenAddresses.indexOf(currentTokenAddress)
  const isLPToken =
    currentToken.marketType === model.MarketType.lp ||
    props.lpToken?.address === currentTokenAddress
  const tokenValue = isLPToken
    ? props.amountStable || 0
    : pickValueBasedOnCurrency(
        selectedCurrency,
        props.amountsStable && props.amountsStable[currentTokenIndex],
        props.amountsNative && props.amountsNative[currentTokenIndex]
      )

  const onClick = (): void => {
    if (props.onClickRow) {
      props.onClickRow()
    }
  }

  const getInnerRow = (): React.ReactElement => {
    return (
      <>
        <div className="type" />
        <div className="own">{ownTrade && <TransactionOwn />}</div>
        <TransactionsAmountTokens
          amounts={props.amounts}
          symbols={props.symbols}
          transactionType={props.transactionType}
          quantityTokensTooltip={4}
        />
        <div className="price" title={isTitleNaN(numberWithCommas(tokenValue))}>
          <div className="price__value">
            {selectedCurrency === 'USD' && <span className="sign">$</span>}
            <span className="value">
              {financialFormat(tokenValue, {
                roundBigNumbersFrom: 'M',
              })}
            </span>
            {selectedCurrency !== 'USD' && (
              <span className="sign">&nbsp;{currencyDisplayName}</span>
            )}
          </div>
          {props.tradeBarData && (
            <div className="meter">
              <TokenValueBar
                shouldDisplay={true}
                tradeBarData={props.tradeBarData}
                tradeAmount={tokenValue}
                selectedCurrency={selectedCurrency}
                isPositive={props.transactionType === 'mint'}
              />
            </div>
          )}
        </div>
        <div className="emoji">
          <div className="icon">
            {isValidLiquidityProviderCategory([walletCategory]) && walletCategory && (
              <CategoryIcon category={walletCategory} />
            )}
          </div>
        </div>
        <TimeAgo timestamp={props.timestamp} />
      </>
    )
  }

  const commonProps = {
    className: classNames(
      'history-content__trow',
      `history-content__trow--${deltaClassName}`,
      props.classAnimated
    ),
    'data-testid': `table-row-${deltaClassName}`,
  }

  const walletAddress = props.wallets?.length ? props.wallets[0] : ''
  const tradeId = props.transactionAddress
  const poolAddress = props.poolAddress

  const rowUrl = buildTraderProfileUrl({
    urlParams: {
      walletAddress,
      tradeId,
    },
    filters: {
      pool_address: poolAddress,
    },
  })

  return (
    <>
      {!isMobile && (
        <Link {...commonProps} to={rowUrl} onClick={onClick}>
          {getInnerRow()}
        </Link>
      )}
      {isMobile && (
        <a
          href={`${networkConfig.block_explorer.url}/tx/${props.transactionAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          {...commonProps}>
          {getInnerRow()}
        </a>
      )}
    </>
  )
}
export default PoolActivityRow
