import { useWeb3React } from '@telekomconsalting/core'
import capitalize from 'lodash.capitalize'
import React, { FC, Reducer, useReducer } from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../reducers'
import { TxsLoaderAction, txsLoaderReducer, TxsLoaderReducerState } from '../../services/TxsLoader'
import useLoadTransactions from '../../services/TxsLoader/useLoadTransactions'
import TableWithFilters from '../TableWithFilter/TableWithFilters'
import FullHistoryLink from './FullHistoryLink'
import getUpdatePoolActivityFilters from './getUpdatePoolActivityFilters'
import fetchPoolActivityTxs from './helpers/fetchPoolActivityTxs'
import getIsPopupOpened from './helpers/getIsPopupOpened'
import { initPoolActivityState } from './helpers/initPoolActivityState'
import mapResponseDataForPoolActivity from './mapResponseDataForPoolActivity'
import { PoolActivityFilterNames, PoolActivityMessages } from './PoolActivity.enums'
import PoolActivityRow from './PoolActivityRow'
import usePoolActivityManager from './usePoolActivityManager'

interface PoolActivityProps {
  fullSidebarUrl: string
}

// init

// 1. wait for calclulate table row height

// after init

// 2. clear update timer
// 3. start to fetch txs
// 4. launch tx updating timer -> 2-5
// 5. success/error result

// 6. observing for changing filter -> 2-5
// 7. observing for changing current token -> 2-5
// 8. observing `isPopupOpen` -> 2-5 - only clear timer

const PoolActivity: FC<PoolActivityProps> = (props) => {
  const { account } = useWeb3React()

  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const currentTokenRecord = React.useMemo(
    () => ({
      id: currentToken?.id,
      marketType: currentToken?.marketType,
    }),
    [currentToken]
  )

  const uiState = useSelector((state: State) => state.ui)
  const isPopupOpened = getIsPopupOpened(uiState)

  const [state, dispatch] = useReducer<Reducer<TxsLoaderReducerState, TxsLoaderAction>>(
    txsLoaderReducer,
    initPoolActivityState
  )

  // load, refresh logic
  useLoadTransactions({
    prepareParams: getUpdatePoolActivityFilters,
    mapData: mapResponseDataForPoolActivity,
    dispatch,
    currentTokenRecord,
    filters: state.filters,
    rowsToShow: state.rowsToShow,
    shouldStopRefresh: isPopupOpened,
    fetchDataFn: fetchPoolActivityTxs,
    name: '🏊‍♂️pa', // pool-activity
  })

  // control over filters
  const poolActivityManager = usePoolActivityManager({ currentToken, state, dispatch })

  const buttons = React.useMemo(
    () => [
      {
        title: capitalize(PoolActivityFilterNames.all),
        value: 'all',
        onClick: poolActivityManager.onAll,
      },
      {
        title: capitalize(PoolActivityFilterNames.adds),
        value: 'adds',
        onClick: poolActivityManager.onAdd,
      },
      {
        title: capitalize(PoolActivityFilterNames.removes),
        value: 'removes',
        onClick: poolActivityManager.onRemove,
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const noDataMessage = state.isLoading
    ? PoolActivityMessages.loadingMessage
    : PoolActivityMessages.emptyMessage

  return (
    <TableWithFilters
      token={currentToken}
      title="Pools Activity"
      modifier="pool-activity"
      filterButtons={buttons}
      renderRow={PoolActivityRow}
      onClickRow={poolActivityManager.onClickPoolActivityRow}
      currentData={state.currentData}
      prevData={state.previousData}
      noDataMessage={noDataMessage}
      isLoadingRows={state.isLoading}
      fullHistoryButtonThreshold={poolActivityManager.fullHistoryButtonThreshold}
      refreshTimestamp={state.refreshTimestamp as number | undefined}
      refreshed={state.isJustRefreshed}
      suffixContent={<FullHistoryLink to={props.fullSidebarUrl} />}
      account={account}
      onChangeRowsToShow={poolActivityManager.onChangeRowsToShow}
    />
  )
}

export default PoolActivity
