import { FilterOptions, MarketType, TokenId } from '../../model'
import { poolActivityActiveFilters, PoolActivityFilterNames } from './PoolActivity.enums'

const isAllPoolActivityTxs = (filters: FilterOptions): boolean =>
  filters.transaction_types ===
  poolActivityActiveFilters[PoolActivityFilterNames.all].transaction_types

const getUpdatePoolActivityFilters = ({
  currentTokenRecord,
  filters,
  rowsToShow,
}: {
  currentTokenRecord: { id?: TokenId; marketType?: MarketType }
  filters: FilterOptions
  rowsToShow: number
}): {
  filters: FilterOptions
  useLastEndpoint: boolean
} => {
  if (!currentTokenRecord.id) {
    throw new Error('currentTokenRecord.id is undefined')
  }
  const limit = isAllPoolActivityTxs(filters) ? 2 * rowsToShow : rowsToShow

  const options = {
    ...filters,
    current_token_id: currentTokenRecord?.id,
    limit,
  }

  return { filters: options, useLastEndpoint: true }
}

export default getUpdatePoolActivityFilters
