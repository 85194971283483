import { MarketType, TokenId, TransactionModel } from '../../model'
import { getTokenAddressFromId } from '../../utils'

export const mapResponseDataForPoolActivity = ({
  rawData,
  tokenId,
  marketType,
}: {
  rawData: TransactionModel[]
  tokenId?: TokenId
  marketType?: MarketType
}): TransactionModel[] => {
  if (marketType !== MarketType.token) {
    return rawData
  }
  if (!tokenId) {
    console.error('tokenId is undefined')
    return []
  }
  const resultData = rawData.filter((tx: TransactionModel) => {
    const currentTokenAddress = getTokenAddressFromId(tokenId)
    if (tx.lpToken?.address === currentTokenAddress) {
      return true
    }
    const currentTokenIndex = tx.tokenAddresses.indexOf(currentTokenAddress)
    return tx.amounts[currentTokenIndex] > 0
  })

  return resultData
}

export default mapResponseDataForPoolActivity
