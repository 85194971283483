const calculatePoolActivityRowsToShow = ({
  theadHeight,
  contentHeight,
}: {
  theadHeight: number
  contentHeight: number
}): number => {
  const tableHeight = contentHeight - theadHeight
  const tradingVolumeRowHeight = 48
  const resultQuantityRows =
    Math.ceil(tableHeight / tradingVolumeRowHeight) < 15
      ? 15
      : Math.ceil(tableHeight / tradingVolumeRowHeight)
  return resultQuantityRows
}

export default calculatePoolActivityRowsToShow
