import { ApiResponseList, FilterOptions, TransactionModel } from '../../../model'
import { fetchTransactionsV3 } from '../../../services/sidebarsService'

const fetchPoolActivityTxs = async ({
  filters,
  useLastEndpoint,
}: {
  filters: FilterOptions
  useLastEndpoint?: boolean
}): Promise<ApiResponseList<TransactionModel>> =>
  await fetchTransactionsV3({
    filters,
    useLastEndpoint,
  })

export default fetchPoolActivityTxs
